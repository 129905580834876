@keyframes loader-dots {
  0%,
  100% {
    transform: scale(0);
  }

  45%, 55% {
    transform: scale(1);
  }
}

.loader {
  display: none;
  width: 100%;
  justify-content: center;
  align-content: center;
  margin-bottom: var(--space-54);

  --dot-size: 1.5rem;

  @include text-20;

  &.is-visible {
    display: flex;
  }

  @include tablet-portrait {
    margin-bottom: var(--space-100);
  }
}

.loader__spinner {
  width: var(--dot-size);
  height: var(--dot-size);
  position: relative;
  margin-right: var(--space-15);
}

.loader__spinner__dot {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background: var(--color-text);
  opacity: 0.6;
  position: absolute;
  top: 0;
  left: 0;
  animation: loader-dots 2s infinite cubic-bezier(0.455, 0.03, 0.515, 0.955);

  &:nth-child(2) {
    animation-delay: -1.0s;
  }
}
