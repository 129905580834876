.footer {
  display: flex;
  flex-direction: column-reverse;
  margin: 0 var(--space-15) var(--space-15);

  @include text-20;

  a {
    text-decoration: none;
    color: currentColor;
  }

  @include tablet-portrait {
    flex-direction: row;
    justify-content: space-between;
  }
}

.footer__contact {
  display: flex;
  flex-direction: column;
}

.footer__contact__address,
.footer__contact__email:last-of-type {
  margin-bottom: 1.25em;
}

.footer__social__items {
  display: flex;
  flex-direction: column;
  margin-bottom: 1.25em;

  span {
    display: none;
  }

  @include tablet-portrait {
    flex-direction: row;
    margin-bottom: 0;

    span {
      display: inline;
    }
  }
}
