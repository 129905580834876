.header {
  width: 100%;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin-bottom: var(--space-54);
  padding: var(--space-15);
  z-index: 10;

  @include text-20;

  a {
    text-decoration: none;
    color: currentColor;
  }

  @include tablet-portrait {
    flex-wrap: nowrap;
  }
}

.header__logo,
.header__nav {
  position: fixed;
}

.header__logo {
  flex: 0 0 auto;
  left: var(--space-15);
}

.header__text {
  margin: 0 var(--space-20);
  text-align: center;

  .header--text & {
    width: 100%;
    order: 3;
    margin: var(--space-54) var(--space-20) 0;

    @include font-serif;
    @include text-22;

    letter-spacing: 0.04em;
  }

  @include tablet-portrait {
    width: unset;
    margin: 0 var(--space-20) 0;
    padding: 0 4.5rem;

    .header--text & {
      order: 2;
      margin: 0 var(--space-20);
    }
  }
}

.header__nav {
  flex: 0 0 auto;
  right: var(--space-15);

  a {
    display: block;
  }

  .header--text & {
    order: 2;
  }

  @include tablet-portrait {
    order: 3;
  }
}
