@mixin font-sans {
  font-family: 'New Rail Alphabet', Helvetica, Arial, sans-serif;
  font-weight: 300;
}

@mixin font-serif {
  font-family: 'Feature Display', 'Times New Roman', Times, Georgia, serif;
  font-weight: 300;
}

@mixin text-17 {
  font-size: 1.0625rem;
  line-height: 125%;
}

@mixin text-20 {
  font-size: 1.25rem;
  line-height: 125%;
  letter-spacing: -0.02em;
}

@mixin text-22 {
  font-size: 1.375rem;
  line-height: 103%;
  letter-spacing: 0;
}

@mixin text-24 {
  font-size: 1.5rem;
  line-height: 103%;
  letter-spacing: 0;
}

@mixin text-36 {
  font-size: 2.25rem;
  line-height: 103%;
  letter-spacing: -0.02em;
}

@mixin text-54 {
  font-size: 3.375rem;
  line-height: 116.6667%;
  letter-spacing: -0.02em;
}

@mixin text-72 {
  font-size: 4.5rem;
  line-height: 116.6667%;
  letter-spacing: -0.02em;
}

@mixin text-80 {
  font-size: 5rem;
  line-height: 100%;
  letter-spacing: -0.02em;
}
