.entries {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: masonry;
  grid-column-gap: var(--gutter);
  grid-row-gap: var(--space-24);
  align-items: start;

  @include text-17;

  a {
    display: block;
    text-decoration: none;
    color: currentColor;
  }

  @include tablet-portrait {
    grid-template-columns: repeat(2, 1fr);
  }

  @include tablet-landscape {
    grid-template-columns: repeat(3, 1fr);
  }
}

.entry {
  margin-bottom: var(--space-24);
}

.entry__asset {
  margin-bottom: var(--space-10);
}
