.article,
.article__content {
  display: grid;
  grid-template-columns: 1fr;
  grid-column-gap: var(--gutter);

  @include tablet-portrait {
    grid-template-columns: repeat(12, 1fr);
  }
}

.article__title {
  grid-column: 1 / -1;
  max-width: 24ch;
  margin: 0 auto var(--space-54);
  text-align: center;

  @include font-serif;
  @include text-36;

  @include tablet-portrait {
    margin: 0 auto var(--space-100);

    @include text-80;
  }
}

.article__media {
  grid-column: 1 / -1;
  margin-bottom: var(--space-54);

  @include tablet-portrait {
    grid-column: 3 / -3;
    margin-bottom: var(--space-100);
  }
}

.article__content {
  grid-column: 1 / -1;
}

.article__text {
  grid-column: 1 / -1;
  max-width: 56ch;

  @include text-20;

  h1,
  h2 {
    margin-bottom: 1em;
  }

  p:not(:first-of-type) {
    text-indent: 2.5em;
  }

  a {
    color: currentColor;
  }

  &:not(:last-child) {
    margin-bottom: var(--space-54);
  }

  @include tablet-portrait {
    grid-column: 7 / span 6;

    &:not(:last-child) {
      margin-bottom: var(--space-100);
    }
  }
}

.article__image,
.article__video {
  grid-column: 1 / -1;
  margin-bottom: var(--space-54);

  @include tablet-portrait {
    grid-column-start: auto;
    grid-column-end: span 1;
    margin-bottom: var(--space-100);
  }
}

.article__image--full,
.article__video--full {
  @include tablet-portrait {
    grid-column: 1 / -1;
  }
}

@for $i from 1 through 11 {
  .article__image--#{$i}cols,
  .article__video--#{$i}cols {
    @include tablet-portrait {
      grid-column-end: span #{$i};
    }
  }

  .article__image--offset#{$i}cols,
  .article__video--offset#{$i}cols {
    @include tablet-portrait {
      grid-column-start: #{$i + 1} !important;
    }
  }
}
