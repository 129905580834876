body {
  background: var(--color-background);
  color: var(--color-text);

  @include font-sans;

  &.is-loading {
    cursor: progress;
  }

  &.is-hidden {
    opacity: 0;
    pointer-events: none;
  }
}

.wrapper,
.main {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.main--article {
  background: var(--color-gray);
}

.content {
  flex: 1;
  margin: 0 var(--space-15) var(--space-54);

  .is-loading & {
    pointer-events: none;
  }

  & + .content {
    margin-top: var(--space-100);
  }

  @include tablet-portrait {
    margin: 0 var(--space-15) var(--space-100);

    & + .content {
      margin-top: var(--space-150);
    }
  }
}

.image,
.video {
  display: block;
  margin: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.video__button {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: var(--color-white);
  border: none;
  border-radius: 0;
  padding: var(--space-10) var(--space-54);
  pointer-events: none;
  transition: opacity 0.4s cubic-bezier(0, 0.55, 0.45, 1);
  box-shadow:
    0 3.4px 2.7px rgba(0, 0, 0, 0.022),
    0 8.7px 6.9px rgba(0, 0, 0, 0.031),
    0 17.7px 14.2px rgba(0, 0, 0, 0.039),
    0 36.5px 29.2px rgba(0, 0, 0, 0.048),
    0 100px 80px rgba(0, 0, 0, 0.07)
  ;



  @include font-sans;
  @include text-17;

  &.is-hidden {
    opacity: 0;
    pointer-events: none;
  }
}

video {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.media-wrapper {
  width: 100%;
  height: 0;
  position: relative;
  padding-top: calc(var(--height) / var(--width) * 100%);
  overflow: hidden;

  .image,
  .video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  .video {
    cursor: pointer;
  }
}
