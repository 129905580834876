@font-face {
  font-family: 'New Rail Alphabet';
  src: url('../../src/assets/fonts/NewRailAlphabet-Light.woff2') format('woff2'),
    url('../../src/assets/fonts/NewRailAlphabet-Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'New Rail Alphabet';
  src: url('../../src/assets/fonts/NewRailAlphabet-Bold.woff2') format('woff2'),
    url('../../src/assets/fonts/NewRailAlphabet-Bold.woff') format('woff');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Feature Display';
  src: url('../../src/assets/fonts/FeatureDisplay-Light-Web.woff2') format('woff2'),
    url('../../src/assets/fonts/FeatureDisplay-Light-Web.woff') format('woff');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Feature Display';
  src: url('../../src/assets/fonts/FeatureDisplay-LightItalic-Web.woff2') format('woff2'),
    url('../../src/assets/fonts/FeatureDisplay-LightItalic-Web.woff') format('woff');
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}
