:root {
  --gutter: 0.625rem;
  --margin: 0;
  --space-10: 0.625rem;
  --space-15: 0.9375rem;
  --space-20: 1.25rem;
  --space-24: 1.5rem;
  --space-30: 1.875rem;
  --space-36: 2.25rem;
  --space-54: 3.375rem;
  --space-100: 6.25rem;
  --space-120: 7.5rem;
  --space-150: 9.375rem;
  --space-200: 12.5rem;
  --cols: 12;
  --color-text: #000000;
  --color-background: #eff1f4;
  --color-white: #ffffff;
  --color-gray: #b9b9b9;
}
