.about {
  display: grid;
  grid-template-columns: 1fr;
  grid-column-gap: var(--gutter);

  @include tablet-portrait {
    grid-template-columns: repeat(12, 1fr);
    grid-row-gap: var(--space-100);
  }
}

.about__text {
  grid-column: 1 / -1;
  max-width: 32ch;
  margin: 0 auto;
  text-align: center;
  margin-bottom: var(--space-54);

  @include font-serif;
  @include text-36;

  h1,
  h2 {
    text-transform: uppercase;
  }

  h1 {
    margin-bottom: 1em;
  }

  p {
    line-height: 1.2;
    letter-spacing: 0;

    &:not(:last-of-type) {
      margin-bottom: 1em;
    }
  }

  em {
    font-style: italic;
  }

  a {
    text-decoration: none;
    color: currentColor;
  }

  @include tablet-portrait {
    grid-column: 2 / -2;
    margin-bottom: 0;

    @include text-54;
  }

  @include desktop {
    @include text-80;
  }
}

.about__image,
.about__video {
  grid-column: 1 / -1;
  margin-bottom: var(--space-10);

  & + .about__text {
    margin-top: var(--space-54);
  }

  @include tablet-portrait {
    grid-column-start: auto;
    grid-column-end: span 1;

    & + .about__text {
      margin-top: 0;
    }
  }
}

.about__image--full,
.about__video--full {
  @include tablet-portrait {
    grid-column: 1 / -1;
  }
}

@for $i from 1 through 11 {
  .about__image--#{$i}cols,
  .about__video--#{$i}cols {
    @include tablet-portrait {
      grid-column: span #{$i};
    }
  }

  .about__image--offset#{$i}cols,
  .about__video--offset#{$i}cols {
    @include tablet-portrait {
      grid-column-start: #{$i + 1} !important;
    }
  }
}
