.case__heading {
  margin: 0 auto var(--space-54);
  max-width: 24ch;
  text-align: center;

  @include text-36;

  @include tablet-portrait {
    margin: 0 auto var(--space-100);

    @include text-72;
  }
}

.case__desc {
  display: flex;
  flex-direction: column-reverse;
  margin: 0 var(--space-15) var(--space-54) var(--space-15);

  @include tablet-portrait {
    display: grid;
    grid-column-gap: var(--gutter);
    grid-template-columns: repeat(12, 1fr);
  }

  @include tablet-landscape {
    margin: 0 0 var(--space-100) 0;
  }
}

.case__desc__col {
  &:first-child {
    margin-top: var(--space-36);
  }

  @include tablet-portrait {
    grid-column: span 6;

    &:first-child {
      margin-top: 0;
    }
  }

  @include tablet-landscape {
    grid-column: span 4;

    &:first-child {
      grid-column: 3 / span 4;
    }
  }

  @include desktop-large {
    grid-column: span 3;

    &:first-child {
      grid-column: 4 / span 3;
    }
  }
}

.case__services {
  @include text-17;
}

.case__services__heading {
  margin-bottom: 1.25em;
}

.case__desc__text {
  max-width: 56ch;

  @include text-17;

  p:not(:first-child) {
    text-indent: 2.5em;
  }

  a {
    color: currentColor;
  }

  strong {
    font-weight: 700;
  }
}

.case__content {
  display: grid;
  grid-template-columns: 1fr;
  grid-column-gap: var(--gutter);
  grid-row-gap: var(--space-10);

  @include tablet-portrait {
    grid-template-columns: repeat(12, 1fr);
  }
}

.case__image,
.case__video {
  grid-column: 1 / -1;

  @include tablet-portrait {
    grid-column-start: auto;
    grid-column-end: span 1;
  }
}

.case__image--full,
.case__video--full {
  @include tablet-portrait {
    grid-column: 1 / -1;
  }
}

@for $i from 1 through 11 {
  .case__image--#{$i}cols,
  .case__video--#{$i}cols {
    @include tablet-portrait {
      grid-column-end: span #{$i};
    }
  }

  .case__image--offset#{$i}cols,
  .case__video--offset#{$i}cols {
    @include tablet-portrait {
      grid-column-start: #{$i + 1} !important;
    }
  }
}

.case__quote {
  grid-column: 1 / -1;
  text-align: center;

  &:not(:first-child) {
    margin-top: var(--space-54);
  }

  &:not(:last-child) {
    margin-bottom: var(--space-54);
  }

  @include tablet-portrait {
    grid-column: 2 / -2;

    &:not(:first-child) {
      margin-top: var(--space-100);
    }

    &:not(:last-child) {
      margin-bottom: var(--space-100);
    }
  }
}

.case__quote__text {
  max-width: 56ch;
  margin: 0 auto var(--space-20);

  @include font-serif;
  @include text-20;

  letter-spacing: 0.04em;
}

.case__quote__author {
  margin-top: var(--space-54) var(--space-15);

  @include text-17;
}

.case__text {
  grid-column: 1 / -1;
  margin: var(--space-54) var(--space-15);
  max-width: 56ch;

  @include text-17;

  p:not(:first-child) {
    text-indent: 2.5em;
  }

  a {
    color: currentColor;
  }

  strong {
    font-weight: 700;
  }

  @include tablet-portrait {
    grid-column: 7 / span 6;
    margin: var(--space-54) 0;
  }

  @include tablet-landscape {
    grid-column: 7 / span 4;
  }

  @include desktop-large {
    grid-column: 10 / span 3;
  }
}
